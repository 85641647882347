@use "../../styles/resets";
@use "../../styles/responsive" as *;
@use "../../styles/variables" as *;
@use "../../styles/fonts";

.letters__card {
  &-date {
    margin-right: 1.6rem;
    margin-left: 1.6rem;
    padding-bottom: 1.6rem;
    margin-bottom: 0;
    font-family: FigTree-SemiBold;

    @include tablet {
      font-size: 3.6rem;
      line-height: 4.4rem;
      font-weight: 700;
      padding-bottom: 3.6rem;
      margin-left: 3.6rem;
      margin-right: 3.6rem;
    }

    @include desktop {
      margin-left: 6.3rem;
      margin-right: 6.3rem;
    }
  }

  &--imagetextdiv {
    @include desktop {
      display: flex;
    }
  }

  &-text {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    font-family: FigTree-Light;

    @include tablet {
      font-size: 1.5rem;
      line-height: 2.2rem;
      margin-left: 3.6rem;
      margin-right: 3.6rem;
    }

    @include desktop {
      font-size:2rem;
      line-height: 2.8rem;
      margin-left: 6.3rem;
      margin-right: 6.3rem;
    }
  }
}
