@font-face{
    font-family: FigTree-Light;
    src: url(../assets/font/Figtree-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: FigTree-Medium;
    src: url(../assets/font/Figtree-Medium.ttf) format("truetype");
    font-weight:500;
    font-style: normal;
}

@font-face {
    font-family: FigTree-SemiBold;
    src: url(../assets/font/Figtree-SemiBold.ttf) format("truetype");
    font-weight:600;
    font-style: normal;
}