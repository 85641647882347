@use "../../styles/resets";
@use "../../styles/responsive" as *;
@use "../../styles/variables" as *;
@use "../../styles/fonts";

.header {
  background-color: #000000;
  color: white;
  display: flex;
  padding-bottom: 0.5rem;

  &__title {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    font-family: FigTree-SemiBold;

    @include tablet {
      margin-left: 3.6rem;
      margin-right: 3.6rem;
      font-size: 6rem;
      line-height: 6.8rem;
    }
  }
}
