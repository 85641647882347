@use "../../styles/resets";
@use "../../styles/responsive" as *;
@use "../../styles/variables" as *;
@use "../../styles/fonts";

.returnhome{
  margin-left:1.6rem;
  margin-right: 1.6rem;
  font-family: FigTree-Light;
}

.title{
 &__text{
  padding-top:2.4rem;
  padding-bottom: 1.6rem;
  margin-left:1.6rem;
  margin-right: 1.6rem; 
  margin-bottom: 0;
  margin-top: 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-family: FigTree-SemiBold;

  
  @include tablet{
    margin-left: 3.6rem;
    margin-right: 3.6rem;
    font-size: 6rem;
    line-height: 6.8rem;
    font-weight: bold;
  }

  @include desktop {
    margin-left: 6.3rem;
    margin-right: 6.3rem;
  }
 }


}


