@use "../../styles/resets";
@use "../../styles/responsive" as *;
@use "../../styles/variables" as *;



.imageslider {

    &__container{
        margin-left:1.6rem;
        margin-right:1.6rem;
    }
  &__carousel {
    height: 32rem;
    width: 25rem;

    @include tablet{
      height: 50rem;
      width: 45rem;
    }

    @include desktop{
      height: 100vh;
      width: 50vw;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
  
  }
}
