*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  margin:0;
  padding:0;
  width:100vw;
  min-height: 100vh;
  background-color: #f3f5f6;
}

body {
  font-size: 1.6rem;
  margin: 0;
  width:100vw;
  min-height: 100vh;
  background-color: #f3f5f6;
  
}

img {
  max-width: 100%;
}

