@use "../../styles/resets";
@use "../../styles/responsive" as *;
@use "../../styles/variables" as *;
@use "../../styles/fonts";

.lettergroup {
  background-color: #f3f5f6;

  &__title {
    margin-left: 1.6rem;
    margin-top: 0;
    font-family: FigTree-Medium;

    @include tablet {
      margin-left: 3.6rem;
      font-size: 3.6rem;
      line-height: 4.2rem;
    }
  }

  &__cards {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__card {
    position: relative;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    margin-bottom: 1.6rem;

    @include tablet {
      margin-left: 3.6rem;
      margin-right: 3.6rem;
      margin-bottom: 2.4rem;
    }
  }

  &__image {
    width: 280px;
    height: 165px;

    @include tablet {
      width: 300px;
      height: 200px;
    }
  }

  &__overlay-text {
    position: absolute;
    font-size: 2rem;
    top: 85%;
    left: 25%;
    transform: translate(-50%, -50%);
    text-shadow: 0 0.5px 3px #000;
    color: white;

    font-weight: bold;
    font-family: FigTree-Medium;
  }
  @include tablet {
    font-size: 0rem;
  }
}
