@use "../../styles/resets";
@use "../../styles/responsive" as *;
@use "../../styles/variables" as *;
@use "../../styles/fonts";

.abouttom {
  margin-left: 1.6rem;
  margin-right: 1.6rem;

  @include tablet {
    margin-left: 3.6rem;
    margin-right: 3.6rem;
  }

  @include desktop {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  &__title {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
    font-size: 2.4rem;
    font-family: FigTree-SemiBold;

    @include tablet {
      margin-bottom: 3.6rem;
    }
  }
}

.sectionone {
  font-family: FigTree-Light;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 1.6rem;

  @include tablet {
    align-items: normal;
    margin-bottom: 3.6rem;
  }

  @include desktop {
    justify-content: space-evenly;
    margin-bottom: 3.6rem;
  }

  &__txt {
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-right: 1.6rem;

    @include tablet {
      font-size: 2.2rem;
      line-height: 3.2rem;
      font-weight: 400;
    }

    @include desktop {
      font-size: 2.2rem;
      line-height: 2.3;
      width: 40vw;
      justify-content: space-evenly;
    }
  }

  &__imagediv {
    @include desktop {
      width: 30%;
    }
  }

  &__image {
    @include desktop {
      width: 50%;
      margin-left: 2.4rem;
    }
  }

  &__image-txt {
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.2rem;

    @include tablet {
      font-size: 1.3rem;
    }

    @include desktop {
      width: 50%;
      padding-left:4rem;
    }
  }
}

.sectiontwo {
  font-family: FigTree-Light;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @include tablet {
    align-items: normal;
  }

  @include desktop {
    justify-content: space-evenly;
    margin-bottom: 3.6rem;
  }

  &__imagesdiv {
    display: flex;
    flex-direction: column;
  }
  &__image {
    @include desktop {
      width: 50%;
      margin-left: 2.4rem;
    }
  }
  &__txt {
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-right: 1.6rem;

    @include tablet {
      font-size: 2.2rem;
      line-height: 3.2rem;
      font-weight: 400;
    }
    @include desktop {
      font-size: 2.2rem;
      line-height: 2.3;
      width: 40vw;
      justify-content: space-evenly;
      margin-left:20rem;
    }
  }

  &__image-txt {
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.2rem;

    @include tablet {
      font-size: 1.3rem;
    }

    @include desktop{
      width:50%;
    }
  }
}

.sectionthree {
  font-family: FigTree-Light;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @include desktop{
    justify-content:center;
  }

  &__imagediv {
    display: flex;
    flex-direction: column;

    @include desktop{
      justify-content: center;
      align-items: center;
    }
  }
  &__txt {
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-right: 1.6rem;

    @include tablet {
      font-size: 1.8rem;
      line-height: 3.2rem;
      font-weight: 400;
    }

    @include desktop{
      display: none;
    }
  }
  &__image-txt {
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.2rem;
    
    @include tablet{
      font-size: 1.3rem;
    }
    
  }


}

.sectiontwo__txt-dsk{
  display: none;

@include desktop{
margin-top:3.6rem;
display:flex;
font-size: 2.2rem;
line-height: 2.3;
width: 40vw;
justify-content: space-evenly;
margin-left:20rem;

}
}
