
@use "../../styles/resets";
@use "../../styles/responsive" as *;
@use "../../styles/variables" as *;
@use "../../styles/fonts";

section{
  margin-left:1.6rem;
  margin-right: 1.6rem;

  @include tablet{
    margin-left: 3.6rem;
    margin-right: 3.6rem;
  }

  @include desktop{
    margin-left: 6.3rem;
    margin-right: 6.3rem;
  }
}

.thumbnail-table {
    width: 100%;
    border-collapse: collapse;
  
    th, td {
      border: 1px solid #ddd; 
      padding: 8px; 
      text-align: left; 
    }
  
    img {
      width: 100px; 
      height: auto; 
      border-radius: 5px; 
    }
  
    th {
      background-color: #f2f2f2; 
      color: black; 
    }
  
   
  }
  