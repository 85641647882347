@use "../../styles/resets";
@use "../../styles/responsive" as *;
@use "../../styles/variables" as *;
@use "../../styles/fonts";


.bio {
  background-color: #f3f5f6;
  padding-left: 1.6rem;
  padding-right: 1.6rem;

  @include tablet {
    padding-left: 3.6rem;
    padding-right: 3.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include desktop{
    align-items: flex-start;
    padding-right:6.3rem;
    padding-left: 6.3rem;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 2.4rem;
    padding-bottom: 1.6rem;
    display: flex;
    justify-content: center;
    font-family:FigTree-SemiBold;

    @include tablet {
      justify-content: flex-start;
      margin-bottom: 10px;
      margin-top:0;
      padding-top: 3.6rem;
      font-size: 3.6rem;
      line-height: 4.4rem;
    }
  }

  &__headshot {
    width: 45vw;
    height: 45vh;
    
    margin-bottom: 1.6rem;
    display: none;

    @include tablet {
      display:inline-block;
      width: 250px;
      height: 250px;
      margin-bottom: 3.6rem;
      margin-top:10px;
    }

    @include desktop {
      height: 45rem;
      width: 38rem;
      margin-top: 3.6rem;
     
     
    }

    &--mobile{
      @include tablet{
        display: none;
      }

      @include desktop{
        display: none;
      }
    }
  }

  &__text {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    margin-bottom: 2.4rem;
    font-family: FigTree-Light;

    @include tablet {
      margin-top:0;
      margin-left: 2.4rem;
      margin-right: 2.4rem;
      margin-bottom: 3.6rem;
      font-size: 2rem;
      line-height: 3rem;
      width:40rem;
    }
    @include desktop{
      width: 60rem;
      margin-left: 0;
    }

  }


  &__text__image-div {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    @include tablet {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__buttons{

&-button{
background-color: #128fdc;
color: #ffff;
text-decoration: none;
height: 4rem;
width: 12rem;
border: none;
margin: 2.4rem 1.6rem;
border-radius: 3px;
font-size: 16px;
font-family: FigTree-Light;

&:hover{
  cursor: pointer;
}

@include desktop{
  margin-left: 0;
}
}

    &-container{

    }
  }
}
